<template>
    <div>
        <div v-if="teams " class="container "> 
            <br />
            <div class="row" >
              <div  class=" col-12 col-md-9"> 

   <div ref="joinTeam"  v-if="teams.length == 0" style="text-align:center" class="box"> 
        <h3>  Join a Team</h3>
        <div class="input-wrapper">
  <PincodeInput
    v-model="pin"
    @input="runPin"
    placeholder="0"
    :length="7"
  />
</div>
<br />
<div v-if="isWrong"><b>The pin is not right, try again!</b><br /></div>  or<br /><br />
          <button @click="$router.push('/createTeam')" class="btn btn-success">+ Create Your Own Team</button>
        </div>

              </div>
            <div v-if="teams[currentTeam]" class="col-12 col-md-9" style="text-align:left">
                <div   class="box" >
                <h2>{{ teams[currentTeam].name }}</h2>
                <p style="color:grey">{{ teams[currentTeam].sport }} · {{ teams[currentTeam].members.length }} member{{ teams[currentTeam].members.length == 1 ? "" : "s" }}<br />
                <small>{{teams[currentTeam].location.formatted_address }}</small>
                </p>
                
                
                </div>
          
                
                 <br />
                 <div  class="" >
                    <div class="p-2" >
                 <div class="row select_sec">
                          <div class="col-4 " @click="selectedMenu = 'breakdowns'" v-bind:class="selectedMenu == 'breakdowns' ? 'select_highlight' : 'select_none'">
                            Breakdowns
                    </div> 
                       <div class="col-4 " @click="selectedMenu = 'announcements'; getAnnoucements()" v-bind:class="selectedMenu == 'announcements' ? 'select_highlight' : 'select_none'">
                        Announcements
                    </div> 
                       <div class="col-4 " @click="selectedMenu = 'members'; getMembers()" v-bind:class="selectedMenu == 'members' ? 'select_highlight' : 'select_none'">
                          Members
                    </div> 
                    </div> 
                 </div> 
               
          


            </div>
                <br />
                 <div class="box" v-if="selectedMenu == 'members'">
      
               <div v-for="(user2 ,index) in teams[currentTeam].membersPulled" v-bind:key="index"> 
                 {{ user2.displayName || user2.email || user2.phoneNumber }} <span v-if="teams[currentTeam].can_edit.includes(user2._id)">  (Staff) </span> <span v-else>  <span v-if="teams[currentTeam].can_edit.includes(user.uid)"> <button class="btn btn-dark" @click="makeStaff(user2._id)" > + Staff</button> </span></span>
               <hr />
               </div>
          
             </div>
             <div class="box" v-if="selectedMenu == 'breakdowns'">
           
     <h5>Game Breakdowns</h5>
     <div v-if="teams[currentTeam].can_edit.includes(user.uid) ">
     <div v-if="teams[currentTeam].elite && (teams[currentTeam].elite.expires * 1000) > new Date().getTime() || teams[currentTeam].events.length < 5">
                            <button @click="$router.push('/createEvent/'+teams[currentTeam]._id)"  class="btn btn-dark">+ Add Event</button>
     </div>
     <div v-else><b>Need to be 'Elite Team' to create more events</b></div>
       </div>       
          <br v-if="teams[currentTeam].can_edit.includes(user.uid)" />
               
          
           <table id="customers" >
  <tr>
    <th>Date</th>
    <th>Opponent</th>
   
  </tr>
  <tr v-for="(event ,index) in teams[currentTeam].events" v-bind:key="index" @click="$router.push('/watch/'+event.id)" style="cursor:pointer; ">
    <td>{{ new Date(event.date).toLocaleDateString() }}</td>
    <td>{{ event.opponent }} <i class="fas fa-chevron-circle-right"></i></td>
  </tr>
  
</table>
<div v-if="teams[currentTeam].events.length == 0 " style="width:100%; text-align:center">
  <br />
  <p>Welcome to GameTaper! This is your team's homepage. Add you first game film:</p>
<button @click="$router.push('/createEvent/'+teams[currentTeam]._id)"  class="btn btn-success">Add</button>
</div>
            </div>

    <div id="announcements" ref="announcements" v-if="selectedMenu == 'announcements'">
           <div v-if="teams[currentTeam].can_edit.includes(user.uid)" >
      
             <div class="box">
               <h5>Create Announcement</h5>
               <hr />
                         <textarea class="form-control "  v-model="new_annoucement_content" rows="2"></textarea>
        
<br>
          <button @click="add_annoucement" class="btn btn-success">Send Announcement</button><br />
        
               </div>
               </div>
               <br />
               <div v-for="(message ,index) in teams[currentTeam].annoucementPulled" v-bind:key="index"> 
                 <div class="box">
                 <b>{{ message.author_name}}</b><br /> <small>{{ new Date(message.timestamp * 1000).toLocaleDateString()}} <span style="cursor:pointer" @click="delete_annoucement(message._id)" v-if="teams[currentTeam].can_edit.includes(user.uid)"><i   class="fas fa-trash"></i></span> </small><hr />
                 {{ message.content}}
                 
               </div>
  <br />
          </div>
          <div style="width:100%; text-align:center" v-if="teams[currentTeam].annoucementPulled && teams[currentTeam].annoucementPulled.length == 0">
            No Team Announcements

          </div>
             </div>
             
               <br />  
              </div>
            <div class=" col-12 col-md-3">
              <div v-if="teams && teams[currentTeam] ">
                <div class="box" style="text-align:center" v-if=" teams[currentTeam].can_edit.includes(user.uid)" >
                   <h4 style="">Team Managment</h4>
                
                  <p>
                    Invite Code: <span v-if="teams[currentTeam].elite && (teams[currentTeam].elite.expires * 1000) > new Date().getTime() || teams[currentTeam].members.length < 15">{{teams[currentTeam].access_pin.toString().replace(/\B(?=(\d{4})+(?!\d))/g, "-") }}</span><span v-else>Need to Upgrade</span><br />
                    Team Type: <span v-if="teams[currentTeam].elite && (teams[currentTeam].elite.expires * 1000) > new Date().getTime()">Elite <br />Expires: {{ new Date((teams[currentTeam].elite.expires * 1000)).toLocaleDateString()}} </span><span v-else> Free (Limited to 4 sporting events and 15 members)<br /><br />  <button @click="purchase" class="btn btn-success">Upgrade Team</button><br /> <br />     <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :customerEmail="user.email"
      :session-id="session"
    />
                  <small>Give your team an edge and upgrade to 'Team Elite' status today for $35 / year. Gain access to unlimited game analytics tools for your entire season!</small>
            <button  @click="$router.push('/connect/seasoncast/'+teams[currentTeam]._id)" style="font-size:small" class="btn btn-link">Connect to SC Premium</button>
              </span>
              </p>
                </div> </div>
                                    <br v-if="teams && teams[currentTeam] && teams[currentTeam].can_edit.includes(user.uid) " />

                  <div style="text-align:center" class="box">
            <h4>Your Account</h4>
             <p>{{user ? user.displayName || user.email || user.phoneNumber : ""}}</p>
            
            <small style="color:grey; cursor:pointer" @click="logOut">[Logout]</small>
                      
                               
            </div>
              <br />
                   <div  style="text-align:center" class="box">
           
             <h4>My Teams</h4>
            <div v-for="(team ,index) in teams" v-bind:key="index">
             <div style="cursor:pointer" @click="currentTeam = index; selectedMenu = 'breakdowns'"><span v-if="currentTeam == index" ><i class="fas fa-check-circle"></i></span> {{team.name }} </div>
              <hr />
            </div>
                                    <button @click="$router.push('/joinTeam')" class="btn btn-link">+ Add Team</button>

                               
            </div>
            <br />
                </div>
             </div>
        </div>
    </div>
</template>
<script>

import firebase from "firebase";
require("firebase/firestore");
var db = firebase.firestore();
import PincodeInput from 'vue-pincode-input';
import { StripeCheckout } from '@vue-stripe/vue-stripe';


require("firebase/functions");
 var functions = firebase.functions()

export default {
     name: "Dashboard",
         components:{
        PincodeInput,
  StripeCheckout,
        
},
       data() {
    return {
      user: null,
      events: [],
      teams: undefined,
      currentTeam: 0,
      selectedMenu: "breakdowns",
      members: undefined,
      new_annoucement_content: "",
      pin: "",
        isWrong: false,
        publishableKey: "pk_live_FIoO3Hm9m8ya2XvhlMtcv6lt",
         session: undefined, 
    };
  },
    mounted: async function (){
         firebase.auth().onAuthStateChanged(async (user) =>  {
      if (user) {
        this.user = user;
        if (!this.user.displayName){
          this.$router.push('/updateUser')
        }
        this.getTeamEvents()


  // dataA.members.push(context.auth.uid)
  // db.collection("Teams").doc(team_id).set(dataA)
  // .catch(console.error)

      } else {
         this.$router.push('/login')
      }
    });
    },
    methods: {
      purchase: async function(){
 var loader
        var  addMessage = functions.httpsCallable('PaymentSession')
                 loader = this.$loading.show({
                  // Optional parameters
                  container:  this.$refs.joinTeam,
          
                });
              var result = await  addMessage({ team_id:this.teams[this.currentTeam]._id, email: this.user.email || "" })

    console.log(result)
loader.hide()
this.session = result.data.session.id
  //       this.lineItems[0].metadata = {
  //         team_id: this.teams[this.currentTeam]._id
  //       }
  this.$refs.checkoutRef.redirectToCheckout();
      },
       runPin: async function(){
            var loader
            try {
           if (this.pin.length != 7){return}
                this.isWrong = false

                 loader = this.$loading.show({
                  // Optional parameters
                  container:  this.$refs.joinTeam,
          
                });
        
               var  addMessage = functions.httpsCallable('joinByCode')
                
              var result = await  addMessage({ join_pin: parseInt(this.pin) })

    console.log(result)
     loader.hide()

     if (!result.data.success){
         this.isWrong = true
         this.pin = ""
     }else{
             this.$router.go(0);

     }

            }catch(error){
                loader.hide()
console.error(error)
            }},
             async delete_annoucement(id){
               await db.collection("Teams/"+this.teams[this.currentTeam]._id+"/announcements").doc(id).delete()
             },
      async add_annoucement(){
        var content = this.new_annoucement_content
     db.collection("Teams/"+this.teams[this.currentTeam]._id+"/announcements").add({
                 author_uid: firebase.auth().currentUser.uid,
                author_name: firebase.auth().currentUser.displayName || firebase.auth().currentUser.email || firebase.auth().currentUser.phoneNumber,
                can_see: ["public"],
                timestamp: (new Date().getTime()) / 1000,
                content: this.new_annoucement_content
      })
      this.new_annoucement_content = ""

      await this.getMembers()
      var emails = []
      for (let index = 0; index < this.teams[this.currentTeam].membersPulled.length; index++) {
        const element = this.teams[this.currentTeam].membersPulled[index];

        if (element.email){
          emails.push(element.email)
        }
        
      }

       var loader
        var  addMessage = functions.httpsCallable('emailMessage')
                 loader = this.$loading.show({
                  // Optional parameters
                  container:  this.$refs.announcements,
          
                });
              var result = await  addMessage({ team_name:this.teams[this.currentTeam].name, emails: emails, content:  content})

    console.log(result)
loader.hide()

      },
      async getAnnoucements(){
   db.collection("Teams/"+this.teams[this.currentTeam]._id+"/announcements").where("can_see", "array-contains-any", ["public"]  ).orderBy('timestamp', 'desc').limit(100)
    .onSnapshot((querySnapshot) => {
        this.teams[this.currentTeam].annoucementPulled = [];
        querySnapshot.forEach((doc) => {
            this.teams[this.currentTeam].annoucementPulled.push({...doc.data(), _id: doc.id});
        });
        console.log(querySnapshot)
      this.$forceUpdate()
    });
      },
      async makeStaff(uid){
        this.teams[this.currentTeam].can_edit.push(uid)
        db.collection("Teams").doc(this.teams[this.currentTeam]._id).set({ can_edit: this.teams[this.currentTeam].can_edit}, { merge: true })
      },
      async getMembers(){
        if (this.teams[this.currentTeam].membersPulled){
          return
        }
    
  let itemRefs = this.teams[this.currentTeam].members.map(id => {
    return db.collection('Users').doc(id).get();
  });

  
      var usersRef = await  Promise.all(itemRefs)

          let users = usersRef.map(doc => { return {_id:doc.id, ...doc.data()}});

      this.teams[this.currentTeam].membersPulled = users

      this.$forceUpdate()

      },
        async getTeamEvents(){
          
var querySnapshot = await db.collection("Teams").where("members", "array-contains", this.user.uid).get()
this.teams = []
    querySnapshot.forEach((doc) => {
     var docIn =  doc.data()
     docIn._id = doc.id
        this.teams.push(docIn)
    });

      
        },
      async   createEvent(){
var result = await db.collection("Events").add({
    author_uid: this.user.uid,
    team: 123,
    opponent: "Western Catholitc",
    date: new Date().getTime(),
    clips: 200

})
console.log(result)
        },
    logOut() {
      firebase.auth().signOut().then(() => {
        firebase.auth().onAuthStateChanged(() => {
          this.$router.push('/')
        })
      })
    }
  }
}
</script>
<style>
.select_sec{
  text-align: center;
  
}
.select_none{
    border-bottom: 1px solid rgb(182, 182, 182);
    height: 2em;
    cursor: pointer;
    color: rgb(95, 95, 95);
}
.select_highlight{
   height: 2em;
  border-bottom: 1px solid rgb(31, 128, 23);
  cursor: pointer;
}
.select_none:hover {
color: black;

}
.select_highlight:hover {
color: black;

}
.box{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
   
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}
</style>